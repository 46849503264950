import React from 'react';
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import ServiceDetails from '../components/ServiceDetails/ServiceDetails';
import Header from '../components/Index/Header';
 
export default function Details() {
    return (
        <Layout>
            <Navbar />
            <Header />
            <ServiceDetails />
            <Footer />
        </Layout>
    );
};